import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Markdown from "./Markdown";
import PropTypes from "prop-types";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
}));

export default function Main(props) {
  const classes = useStyles();
  const { posts, title } = props;
  const [postsText, setPostsText] = React.useState([]);
  React.useEffect(() => {
    Promise.all(
      posts.map(({ post }) => fetch(post).then((r) => r.text()))
    ).then((results) => setPostsText(results));
  }, []);

  return (
    <Grid item xs={12} md={8}>
      {title ? (
        <>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          <Divider />
        </>
      ) : null}
      {postsText.length > 0 &&
        postsText.map((post, idx) => (
          <Markdown className={classes.markdown} key={idx}>
            {post}
          </Markdown>
        ))}
    </Grid>
  );
}

Main.propTypes = {
  posts: PropTypes.array,
  title: PropTypes.string,
};
