import React from "react";
import Container from "@material-ui/core/Container";

import About from "./About";
import Blog from "./Blog";
import Header from "./Header";
import NotFoundPage from "./NotFound.js";
import { Route, Router, Switch, useParams } from "./util/router.js";
import Main from "./Main";

import { featuredPosts, posts, sidebar, mainFeaturedPost } from "./posts";

const sections = [
  // { title: "Technology", url: "/tech" },
  // { title: "About", url: "/about" },
  // { title: "Culture", url: "#" },
  // { title: "Business", url: "#" },
  // { title: "Politics", url: "#" },
  // { title: "Opinion", url: "#" },
  // { title: "Science", url: "#" },
  // { title: "Health", url: "#" },
  // { title: "Style", url: "#" },
  // { title: "Travel", url: "#" },
];

const Post = () => {
  const { id } = useParams();
  if (!id) {
    return null;
  }
  const filteredPosts = posts.filter((p) => `${p.id}` === `${id}`);
  return (
    <>
      <Main posts={filteredPosts} />
    </>
  );
};

export default function App(props) {
  return (
    <Container maxWidth="lg">
      <Header title="OnWeb City" sections={sections} />
      <Router>
        <Switch>
          <Route exact path="/" component={Blog} />
          <Route exact path="/posts/:id" component={Post} />
          <Route exact path="/about" component={About} />
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </Container>
  );
}
