import React from "react";

import FacebookIcon from "@material-ui/icons/Facebook";
import GitHubIcon from "@material-ui/icons/GitHub";
import TwitterIcon from "@material-ui/icons/Twitter";

import post1 from "./blog-post.1.md";
import post2 from "./blog-post.2.md";
import post3 from "./blog-post.3.md";

export const mainFeaturedPost = {
  title: "Jog in the park: iPhone controller for a running game",
  description:
    "In this post we cover the basics of creating an iPhone controller to control your 3d game in the browser.",
  image: "https://source.unsplash.com/eCktzGjC-iU",
  imgText: "main image description",
  linkText: "Continue reading…",
  url: "/posts/1",
};

export const featuredPosts = [
  {
    title: "Stripe Course",
    date: "Nov 11",
    description: "Stripe Course",
    image: "https://source.unsplash.com/Q59HmzK38eQ",
    imageText: "Image Text",
    url: "/posts/2",
  },
  {
    title: "Ionic Course",
    date: "Nov 12",
    description: "Ionic Course",
    image: "https://source.unsplash.com/bMTl6uFMONg",
    imageText: "Image Text",
    url: "/posts/3",
  },
];

export const posts = [
  {
    id: 1,
    post: post1,
    title: "Jog in the park: iPhone controller for running game",
  },
  { id: 2, post: post2, title: "Stripe Course" },
  { id: 3, post: post3, title: "Ionic Course" },
];

export const sidebar = {
  title: "About",
  description:
    "Etiam porta sem malesuada magna mollis euismod. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur.",
  archives: posts.map(({ id, title }) => ({ title, url: `/posts/${id}` })),
  social: [
    { name: "GitHub", icon: GitHubIcon },
    { name: "Twitter", icon: TwitterIcon },
    { name: "Facebook", icon: FacebookIcon },
  ],
};
